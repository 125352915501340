import {
  Injectable
}

  from "@angular/core";

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
}

  from "@angular/common/http";

import {
  Observable,
  throwError
}

  from "rxjs";

import {
  map,
  catchError
}

  from "rxjs/operators";

import {
  NotifyService
}

  from "./notify.service";

import {
  ResponseService
}

  from "./response.service";

import {
  LoaderService
}

  from "./loader.service";

import {
  Router
}

  from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

/**
 *
 *
 * @export
 * @class InterceptService
 * @implements {HttpInterceptor}
 */
@Injectable({
  providedIn: "root"
}

) export class InterceptService implements HttpInterceptor {

  constructor(
    private notifyService: NotifyService,
    private responseService: ResponseService,
    private loaderService: LoaderService,
    private router: Router,
    private keycloackService: KeycloakService
  ) { }

  // intercept request and add token

  /**
 *
 *
 * @param {HttpRequest<any>} request
 * @param {HttpHandler} next
 * @returns {Observable<HttpEvent<any>>}
 * @memberof InterceptService
 */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    this.loaderService.show();

    return next.handle(request).pipe(map(event => {
      if (event instanceof HttpResponse) {
        this.loaderService.hide();

        if (event && event.body && Object.keys(event.body).find(x => x === 'data')) {
          let res = this.responseService.getData(event.body);

          let response = new HttpResponse({
            body: res
          }

          );
          return response;
        }

        else {
          return event;
        }
      }
    }

    ),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';

        this.loaderService.hide();

        if (error.error && error.error.message) {

          this.notifyService.error(error.error.message);

        }

        else {

          this.notifyService.error(error.message);

        }

        switch (error.status) {
          case 0:
            this.router.navigate(['error', '0']);
            break;
          case 401:
            this.keycloackService.logout();
            break;
          // case 404:
          //   this.router.navigate(['error-page', '404']);
          //   break;

          default:
            break;
        }

        return throwError(error);
      }

      ),
    );
  }
}
