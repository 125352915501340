import { KeycloakService } from 'keycloak-angular';

let keycloakData = null;
function getConfig() {
	return new Promise((resolve, reject) => {
		var xmlhttp = new XMLHttpRequest();
		var url = 'assets/config.json';
		xmlhttp.onreadystatechange = function() {
			if (this.readyState == 4 && this.status == 200) {
				keycloakData = JSON.parse(this.responseText).keycloak;
				resolve();
			}
		};
		xmlhttp.open('GET', url, true);
		xmlhttp.send();
	});
}

export function initializer(keycloak: KeycloakService): () => Promise<any> {
	return async (): Promise<any> => {
		await getConfig().finally();
		try {
			await keycloak.init({
				config: {
					url: keycloakData.url,
					realm: keycloakData.realm,
					clientId: keycloakData.clientId,
					credentials: {
						secret: keycloakData.credentials.secret
					}
				},
				initOptions: {
					onLoad: 'login-required',
					checkLoginIframe: false
				},
				enableBearerInterceptor: true
			});
		} catch (error) {
			console.log(error);
		}
	};
}
