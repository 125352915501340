import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-technical-material-detail',
  templateUrl: './technical-material-detail.component.html',
  styleUrls: ['./technical-material-detail.component.scss']
})

export class TechnicalMaterialDetailComponent implements OnInit {
  @Input("tecnicalMaterialDetail") tecnicalMaterialDetail;
  @Output() tecnicalMaterialDetailEmpty = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  emptyTecnicalMaterialDetail() {
    this.tecnicalMaterialDetail = null;
    this.tecnicalMaterialDetailEmpty.emit(null);
  }
}
