import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { LoaderState } from '../models/Loader';

/**
 *
 *
 * @export
 * @class LoaderService
 */
@Injectable({
  providedIn: "root"
})
export class LoaderService {
  loaderState: Observable<LoaderState>;

  private loaderSubject: Subject<LoaderState>;

  constructor() {
    this.loaderSubject = new Subject<LoaderState>();
    this.loaderState = this.loaderSubject.asObservable();
  }

  /**
   *
   *
   * @memberof LoaderService
   */
  show() {
    this.loaderSubject.next(<LoaderState>{ show: true });
  }

  /**
   *
   *
   * @memberof LoaderService
   */
  hide() {
    this.loaderSubject.next(<LoaderState>{ show: false });
  }
}
