import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem, fakeMenuItem } from 'src/models/MenuItem.model';
import { UserRulesService } from 'src/service/user-rules.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {

  @Input("expand") expand: boolean = false;
  @Output("closed") closed = new EventEmitter();


  menuItems: Array<MenuItem> = fakeMenuItem;
  constructor(private userRulesService: UserRulesService, private router: Router) { }

  ngOnInit() {
  }

  collapse(){
    this.expand = false;
    this.closed.emit(false);
  }

  getRulesRoute(item) {

    switch (item.name) {
      case 'purchase order':
        return this.userRulesService.controlRules('po-reader');
      case 'packing list':
        return this.userRulesService.controlRules('pl-reader');
      case 'IPS':
        return this.userRulesService.controlRules('ips-reader');
      case 'Tools':
        return this.userRulesService.controlRules('tools');
      case 'Settings':
          return this.userRulesService.controlRules('settings');
      case 'Export':
        return this.userRulesService.controlRules('ips-export');
      default:
        return false
    }
  }

}
