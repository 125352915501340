/**
 *
 *
 * @export
 * @enum {number}
 */
export enum NotifyType {
  Success,
  Warning,
  Error,
  Info
}
/**
 *
 *
 * @export
 * @class NotifyMessage
 */
export class NotifyMessage {
  index: number;
  message: string;
  isRemoved?: boolean;
  type: NotifyType;
  show: boolean = false;
  constructor(
    index: number,
    message: string,
    type: NotifyType = NotifyType.Success
  ) {
    this.index = index;
    this.message = message;
    this.type = type;
  }
}
