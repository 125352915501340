import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
	{
		path: 'purchase-order',
		loadChildren: './purchase-order/purchase-order.module#PurchaseOrderModule'
	},
	{
		path: 'packing',
		loadChildren: './packing/packing.module#PackingModule'
	},
	{
		path: 'ips',
		loadChildren: './ips/ips.module#IpsModule'
	},
	{
		path: 'requirement',
		loadChildren: './requirement/requirement.module#RequirementModule'
	},
	{
		path: 'user',
		loadChildren: './user/user.module#UserModule'
	},
	{
		path: 'tools',
		loadChildren: './tools/tools.module#ToolsModule'
	},
	{
		path: 'settings',
		loadChildren: './settings/settings.module#SettingsModule'
	},
	{
		path: 'export',
		loadChildren: './export/export.module#ExportModule'
	},
	{
		path: 'error',
		loadChildren: './error-page/error-page.module#ErrorPageModule'
	},
];

@NgModule({
	imports: [ RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }) ],
	exports: [ RouterModule ]
})
export class AppRoutingModule {}
