import { Injectable } from "@angular/core";
import { BaseResponse } from "../models/BaseResponse";
import { NotifyService } from "./notify.service";
/**
 *
 *
 * @export
 * @class ResponseService
 */
@Injectable({
  providedIn: "root"
})

export class ResponseService {
  constructor(private notifyService: NotifyService) { }

  /**
   *
   *
   * @param {BaseResponse<any>} data
   * @returns {*}
   * @memberof ResponseService
   */
  getData(data: BaseResponse<any>): any {
    if (data) {
      if (data.data && !data.error) {
        return data.data;
      } else {
        if (data.error) {
          this.notifyService.error(data.message);
          console.error(data.message);
          return undefined;
        } else {
          return undefined;
        }
      }
    } else {
      this.notifyService.error("Error generic");
      return undefined;
    }
  }
}
