/**
 *
 *
 * @export
 * @class MenuItem
 */
export class MenuItem {
	name: string;
	link: string;
	icon?: string;
}

export const fakeMenuItem: Array<MenuItem> = [
	{
		name: 'purchase order',
		link: '/purchase-order/purchase-orders-list',
		icon: 'ion-android-list'
	},
	{
		name: 'packing list',
		icon: 'ion-ios-albums',
		link: '/packing/packing-list'
	},
	{
		name: 'IPS',
		icon: 'ion-ios-list',
		link: '/ips/ips-list'
	},
	{
		name: 'Settings',
		icon: 'ion-ios-settings-strong',
		link: '/settings/settings'
	},
	{
		name: 'Tools',
		icon: 'ion-wrench',
		link: '/tools/parser-excel'
	},
	{
		name: 'Export',
		icon: 'ion-code-download',
		link: '/export/shipping-invoice'
	},
];
