import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
    baseUrl: string;

    constructor(private httpClient: HttpClient) {

    }

    initialize() {
        return this.httpClient.get('assets/config.json').toPromise().then((response: any) => {
            this.baseUrl = response.apiGateway.url;
        }
        );
    }

}

export function initializeAppConfig(appConfigService: AppConfigService): () => Promise<any> {
    return async (): Promise<any> => {
        await appConfigService.initialize();
    }
}

