import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-vendor-detail',
  templateUrl: './vendor-detail.component.html',
  styleUrls: ['./vendor-detail.component.sass']
})
export class VendorDetailComponent implements OnInit {

  @Input("vendor") vendor;
  @Output() vendorEmpty = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  emptyVendor() {
    this.vendor = null;
    this.vendorEmpty.emit(null);
  }

}
