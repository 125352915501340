import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { NotifyType, NotifyMessage } from "../models/Notify";

/**
 *
 *
 * @export
 * @class NotifyService
 */
@Injectable({
  providedIn: "root"
})
export class NotifyService {

  private observableMessage = new Subject();

  constructor() { }

/**
 *
 *
 * @param {string} msg
 * @memberof NotifyService
 */
success(msg: string) {
    this.observableMessage.next(
      this.createNotifyMessage(msg, NotifyType.Success)
    );
  }

  /**
   *
   *
   * @param {string} msg
   * @memberof NotifyService
   */
  error(msg: string) {
    this.observableMessage.next(
      this.createNotifyMessage(msg, NotifyType.Error)
    );
  }

  /**
   *
   *
   * @param {string} msg
   * @memberof NotifyService
   */
  info(msg: string) {
    this.observableMessage.next(
      this.createNotifyMessage(msg, NotifyType.Info)
    );
  }

  /**
   *
   *
   * @param {(value: NotifyMessage) => void} next
   * @returns
   * @memberof NotifyService
   */
  subscribe(next: (value: NotifyMessage) => void) {
    return this.observableMessage.subscribe(next);
  }

  /**
   *
   *
   * @private
   * @param {string} msg
   * @param {NotifyType} type
   * @returns
   * @memberof NotifyService
   */
  private createNotifyMessage(msg: string, type: NotifyType) {
    let index = Math.random();
    return new NotifyMessage(index, msg, type);
  }
}
