
export const environment = {
  production: false,
  // prefixUrl: window.location.origin + "/api/",
  prefixUrl: require('../assets/config.json').apiGateway.url,
  keycloak: require('../assets/config.json').keycloak,
  name: 'dev'
};

export const baseUrl = environment.prefixUrl;
export const keycloakData = environment.keycloak;
