﻿import {
  Component,
  Injectable,
  OnInit,
  OnDestroy,
  Input,
  Inject
} from "@angular/core";
import { NotifyMessage, NotifyType } from "../../models/Notify";
import { Subscription } from "rxjs";
import { NotifyService } from '../../service/notify.service';

@Component({
  selector: "notify",
  templateUrl: "./notify.component.html",
  styleUrls: ["./notify.component.scss"]
})
@Injectable()
export class NotifyComponent implements OnInit, OnDestroy {
  messages: NotifyMessage;

  show = false;
  @Input()
  isRegister?: boolean;
  @Input()
  fixed?: boolean;
  private subscriber: Subscription = new Subscription ();

  constructor(@Inject(NotifyService) private notifyService) { }

  ngOnInit() {
    this.subscriber = this.notifyService.subscribe((msg: NotifyMessage) => {
      this.messages = msg;
      this.show = true;
      setTimeout(() => {
        this.onRemoveClick();
      }, 30000);
    });
  }

  ngOnDestroy() {
    if (this.subscriber !== undefined) {
      this.subscriber.unsubscribe();
    }
  }

  onRemoveClick() {
    this.messages = null;
    this.show = false;
  }

  cssClass(msg: NotifyMessage) {
    if (!msg) {
      return "alert-dismissable";
    }
    switch (msg.type) {
      case NotifyType.Success:
        return "msg-success";
      case NotifyType.Error:
        return "msg-error";
      case NotifyType.Warning:
        return "msg-warning";
        case NotifyType.Info:
        return "msg-info";
    }
  }
}
