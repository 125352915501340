import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LeftMenuComponent } from './left-menu/left-menu.component';
import { NotifyComponent } from './notify/notify.component';
import { LoaderComponent } from './loader/loader.component';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { InterceptService } from '../service/http-intercept.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader, TranslateService, } from '@ngx-translate/core';
import { SharedModule } from './shared.module';
import { MenuUserProfileComponent } from './menu-user-profile/menu-user-profile.component';

import { KeycloakService, KeycloakAngularModule } from "keycloak-angular";

import { initializer } from './keycloack/keycloack-init';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { AppConfigService, initializeAppConfig } from 'src/service/app-config.service';


registerLocaleData(localeIt, 'it');


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LeftMenuComponent,
    LoaderComponent,
    NotifyComponent,
    MenuUserProfileComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
  ],
  providers: [
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppConfig,
      multi: true,
      deps: [AppConfigService]
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}
