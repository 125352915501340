import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { UserRulesService } from 'src/service/user-rules.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: [ './app.component.scss' ],
	providers: [ UserRulesService ]
})
export class AppComponent {
	title = 'FrontendTecnimont';
	expand = false;
	name: string;
	lastname: string;
	isEnabled: boolean;
	loading = false;
	navigation = 0; //0 is first nav, 1 is navigation start leave content, 2 is navigation end view content

	constructor(
		private router: Router,
		public translate: TranslateService,
		private keycloak: KeycloakService,
		private userRulesService: UserRulesService
	) {

		let userRoles: string[] = this.keycloak.getUserRoles();
		this.keycloak.loadUserProfile(false).then((res) => {
			this.name = res.firstName;
			this.lastname = res.lastName;
		});
		this.userRulesService.rules = this.keycloak.getUserRoles();
		let can: boolean = false;
		userRoles.forEach((role) => {
			if (role === 'ips-access') {
				can = true;
			}
		});

		if (can) {
			this.isEnabled = true;
			this.name = this.keycloak.getUsername();
			if (
				!this.userRulesService.controlRules('po-reader') &&
				!this.userRulesService.controlRules('pl-reader') &&
				!this.userRulesService.controlRules('ips-reader') &&
				!this.userRulesService.controlRules('settings') &&
				!this.userRulesService.controlRules('ips-export') &&
				!this.userRulesService.controlRules('tools')
			) {
				this.isEnabled = false;
				this.router.navigate([ 'user', 'error-access' ]);
			} else {
				if (this.userRulesService.controlRules('po-reader')) {
					this.router.navigate([ 'purchase-order/purchase-orders-list' ]);
				} else if (this.userRulesService.controlRules('pl-reader')) {
					this.router.navigate([ 'packing/packing-list' ]);
				} else if (this.userRulesService.controlRules('ips-reader')) {
					this.router.navigate([ 'ips/ips-list' ]);
				} else if (this.userRulesService.controlRules('tools')) {
					this.router.navigate([ 'tools' ]);
				} else if (this.userRulesService.controlRules('settings')) {
					this.router.navigate([ 'settings' ]);
				} else if (this.userRulesService.controlRules('ips-export')) {
					this.router.navigate([ 'export' ]);
				}
			}
		} else {
			this.isEnabled = false;
			this.router.navigate([ 'user', 'error-access' ]);
		}
	}

	closeMenu(e) {
		this.expand = false;
	}

	logout() {
		this.keycloak.logout();
	}
}
