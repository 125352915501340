import { Directive, HostListener, HostBinding, EventEmitter, Output } from '@angular/core';

@Directive({
    selector: '[drop-zone]',
})
export class DropZoneDirective {

    @Output() fileDropped = new EventEmitter<FileList>();
    @Output() fileHovered = new EventEmitter();


    constructor() { }

    @HostListener('dragover', ['$event']) public onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileHovered.emit(true);

    }
    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileHovered.emit(false);

    }
    @HostListener('drop', ['$event']) public onDrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
            this.fileHovered.emit(false);
        }
    }
}