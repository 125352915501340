import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UserRulesService {
  baseUrl: string;
  rules: string[] = [];

  controlRules(rules: string) {
    return this.rules.indexOf(rules) > -1;
  }
}